window.$ = window.jQuery = require("jquery");
window.velocity = require("velocity-animate");
require("velocity-ui-pack"); // Will just hook on the velocity object
window.Q = require("q");
window.CryptoJS = require("crypto-js");
window.JSEncrypt = require("jsencrypt").default;
window.angular = require("angular");
window.Hammer = require("hammerjs");
window.FastClick = require("fastclick");
window.Sortable = require("sortablejs").default;
window.tinycolor = require("tinycolor2");
window.Snap = require("snapsvg");
window.jsPDF = require("jspdf").default;
window.numbro = require("numbro");
// region Loading all numbro locales
const numbroLangs = require("numbro/dist/languages.min");
Object.values(numbroLangs).forEach(numbro.registerLanguage);

// endregion
window.moment = require("moment-timezone");
// region Lading moment languages
const momentLocalesRequire = require.context("moment/locale", false, /\.js$/);
momentLocalesRequire.keys().forEach(momentLocalesRequire);
// endregion
window.sprintf = require("../../vendor/sprintf").default;
window.panzoom = require("panzoom");
window.adapter = require("webrtc-adapter").default;
window.SimpleKeyboard = require("simple-keyboard");
window.i18next = window.i18n = require("i18next");
window.i18nextHttpBackend = require("i18next-http-backend");
window.jsrsasign = require("jsrsasign");
// region jQuery Extensions
/**
 * jQuery extension to save hide and show elements
 * original toggle uses simply "display: block;" when calling show -> eg. flexbox not working any more
 * overwrites original toggle
 * if more than one parameter or sth. other is given, we call the originalToggle fn
 * @param a
 * @returns {*}
 */


$.fn.originalToggle = $.fn.toggle;

$.fn.toggle = function toggle(a) {
    if (arguments.length === 1 && typeof a === "boolean") {
        return this.css('display', a ? '' : 'none');
    } else {
        return this.originalToggle.apply(this, arguments);
    }
};
/**
 * removes all attributes from element
 * @returns {$.fn}
 */


$.fn.removeAllAttributes = function removeAllAttributes() {
    while (this[0].attributes.length > 0) {
        this[0].removeAttribute(this[0].attributes[0].name);
    }

    return this;
};
/**
 * jQuery plugin to remove all comments of subtree
 * @returns {$}
 */


$.fn.removeComments = function removeComments() {
    this.contents().filter(function () {
        return this.nodeType === 8;
    }).each(function (i, e) {
        $(e).remove();
    });
    return this;
};

/**
 * Helper method to append several divs at once to a list, in a performant way.
 * @param arrayOfWrappers
 * @returns {jQuery}
 */


$.fn.appendEach = function (arrayOfWrappers) {
    // Map the array of jQuery objects to an array of
    // raw DOM nodes.
    var rawArray = jQuery.map(arrayOfWrappers, function (value, index) {
        // Return the unwrapped version. This will return
        // the underlying DOM nodes contained within each
        // jQuery value.
        return value.get();
    }); // Add the raw DOM array to the current collection.

    this.append(rawArray); // Return this reference to maintain method chaining.

    return this;
};

// endregion

// region moment.js Prototypes
moment.prototype.getSecondsSince2009 = function getSecondsSince2009() {
    return this.diff(moment.utc.call(this, [2009, 0, 1, 0, 0, 0]), 'seconds');
};
// endregion
